<template>
  <b-modal
    id="cms-create-referral"
    title="New Referral"
    no-close-on-backdrop
    size="lg"
    @ok="onOk"
  >
    <validation-observer
      ref="cmsReferralForm"
    >
      <b-form @submit.prevent="onSubmit">
        <validation-provider
          v-slot="{ errors }"
          name="title"
          rules=""
        >
          <b-form-group label="Title">
            <b-form-input
              id="title"
              v-model="createReferralForm.title"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="image_src"
          rules=""
        >
          <b-form-group label="Image">
            <CmsFileSelect
              :value="createReferralForm.imageSrc"
              :state="errors[0] ? false : null"
              @update="(value) => (createReferralForm.imageSrc = value || '')"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="content"
          rules=""
        >
          <b-form-group label="Content">
            <vue-editor
              v-model="createReferralForm.content"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="position"
          rules="required"
        >
          <b-form-group label="Position">
            <b-form-input
              id="position"
              v-model="createReferralForm.position"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { VueEditor } from 'vue2-editor'
import { required } from '@validations'

export default {
  components: {
    CmsFileSelect: () => import('../file-select.vue'),
    VueEditor,
  },
  data() {
    return {
      required,

      createReferralForm: {
        position: 0,
        title: '',
        imageSrc: '',
        content: '',
      },
    }
  },
  computed: {
    ...mapState({
      isCreatingCmsSiteReferrals: (state) =>
        state.cms.isCreatingCmsSiteReferrals,
    }),
    ...mapGetters(['siteId']),
  },
  methods: {
    ...mapActions(['createCmsReferrals']),
    async onOk(e) {
      e.preventDefault()
      const valid = await this.$refs.cmsForm.validate()
      if (valid) {
        this.onSubmit(() => this.$bvModal.hide('cms-create-referral'))
      }
    },
    async onSubmit(cb) {
       this.$refs.cmsReferralForm.validate().then( async (success) => {
        if (success) {
          // This is not actually row it a column = =
          await this.createCmsReferrals({
            siteId: this.siteId,
            referrals: [this.createReferralForm],
          })

          cb()
        }
      })
      
    },
  },
}
</script>
